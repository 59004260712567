@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@font-face {
    font-family: 'Inter-Thin';
    src: url('../fonts/Inter-Thin.ttf')
}
@font-face {
    font-family: 'inter-light';
    src: url('../fonts/Inter-Light.ttf')
}
@font-face {
    font-family: 'inter-regular';
    src: url('../fonts/Inter-SemiBold.ttf')
}
@font-face {
    font-family: 'inter-medium';
    src: url('../fonts/Inter-Medium.ttf')
}
@font-face {
    font-family: 'Clan Pro';
    src: url('../fonts/ClanProMedium.TTF')
}
@font-face {
    font-family: 'Clan Pro Regular';
    src: url('../fonts/FFClanProRegular.TTF')
}
@font-face {
    font-family: 'Poppins-Medium';
    src: url('../fonts/Poppins/Poppins-Medium.ttf');
}
@font-face {
    font-family: 'Poppins-Light';
    src: url('../fonts/Poppins/Poppins-Light.ttf');
}

:root {
    --red: hsl(0, 78%, 62%);
    --cyan: hsl(180, 62%, 55%);
    --orange: #FF9800;
    --blue: hsl(212, 86%, 64%);
    --varyDarkBlue: hsl(234, 12%, 34%);
    --grayishBlue: hsl(229, 6%, 66%);
    --veryLightGray: hsl(0, 0%, 98%);
    --color-site:#ff9802;
    --color-site-h:#0061A9;
    --color-site-b:#0061A9;
    --weight1: 200;
    --weight2: 400;
    --weight3: 600;
}

*{
    box-sizing: border-box;
    outline: none;
}
*:focus {
    outline: none;
}
body {
    position: relative;
    font-style: normal;
    line-height: 1.5;
    color: #000000;
}
section {
    background-color: #ffffff;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
}
.cusor-pointer:hover {
    cursor: pointer;
    filter: brightness(1.2);
}
.border-radius-10 {
    border-radius: 10px;
}

.responsive-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.d-footer-logo {
    height: 60px;
    width: 180px;
    border-radius: 0 !important;
}

.footer a, h5 {
    font-size: 1rem !important;
}

.header {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0.1rem  rgb(0 0 0 / 10%) !important;
    box-shadow: 0 0.1rem 0.1rem rgb(0 0 0 / 10%) !important;
    transition: all 0.2s;
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.about-dropdown {
    background-color: #0092FF;
    box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.1);
    height: 0;
    transition: ease all 0.5s;
    overflow: hidden;
}
.show-about-dropdown {
    height: 28px;
}
.linkItem-dropdown {
    font-family: 'Clan Pro Regular', sans-serif;
    padding: 5px 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #fff;
    transition: all 0.5s;
}
.linkItem-dropdown:hover {
    color: var(--color-site);
}

.about-dropdown-mobile {
    height: 0;
    overflow: hidden;
    background: #0092FF;
    border-radius: 8px;
    transition: ease all 0.5s;
}
.show-about-dropdown-mobile {
    height: 218px;
}
.show-about-dropdown-mobile-2 {
    height: 165px;
}

.about-dropdown-mobile .link-itemes {
    font-family: 'Clan Pro Regular', sans-serif;
    text-decoration: none;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
}

.header-content {
    display: flex;
    justify-content: space-between;

}
.cls-1{fill:none;}
.cls-2{clip-path:url(#clip-path);}
.dadanadaN *{
    margin-left: -300px !important;
}
.header .cls-4{fill:#ff9802;}
.logoImageSvg {
    height: 60px;
    width: 180px;
    border-radius: 0 !important;
}
.navigation {
    display: flex;
    gap: 50px;
    text-align: center;
    align-items: center;
}
.linkItem {
    font-family: inter-regular, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #424242;
    transition: all 0.5s;
}
.linkItem:hover {
    color: var(--color-site-b);
    cursor: pointer;
}
.active-link {
    color: var(--color-site-b);
}
.submenu-active {
    color: var(--color-site);
}
.nav-item {
    position: relative;
    color: #424242;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    text-decoration: none;
    padding-bottom: 30px;
}
.nav-item .item-value, .nav-item .item-value .value {
    width: 100%;
    text-decoration: none;
    color: #424242;
}

.nav-item .item-value.active {
    background: #E2F3FF;
    color: #0061A9;
    border-radius: 8px;
    padding: 10px 5px;
}

.linkItem-light {
    text-decoration: none !important;
    color: #616161;
    font-family: inter-regular, sans-serif;
    font-weight: 700;
    font-size: 15px;
    transition: all 0.5s;
}
.globe-icon {
    font-size: 20px;
    color: #616161;
    transition: all 0.5s;
}

.linkItem-light:hover {
    color: var(--blue) !important;
}
.btn-site{
    background-color:  var(--color-site);
    border-radius: 2px;
    color: #ffffff;
    transition: all 0.5s;
}
.btn-site-b{
    background-color:  var(--color-site-b);
    border-radius: 2px;
    color: #ffffff;
    transition: all 0.5s;
}
.btn-site-2:hover {
    background-color:  var(--color-site) !important;
    border-radius: 2px;
    color: #ffffff;
    transition: all 0.5s;
}
.btn-site-outline:hover {
    background-color:  #fff;
    border-radius: 2px;
    color: #1a1919;
    transition: all 0.5s;
}
.btn-site-outline {
    background-color:  transparent;
    height: 40px !important;
    border-radius: 2px;
    color: #fff;
    border: solid 0.1px #fff;
    transition: all 0.5s;
}
.btn-site:hover {
    background-color:  transparent;
    border-radius: 2px;
    color: var(--color-site);
    border: solid 2px var(--color-site);
    transition: all 0.5s;
}
.btn-site-b:hover {
    background-color:  transparent;
    border-radius: 2px;
    color: var(--color-site);
    border: solid 2px var(--color-site);
    transition: all 0.5s;
}
.btn-site-2 {
    background-color:  var(--color-site) !important;
    border-radius: 2px;
    color: var(--color-site);
    border: none !important;
    transition: all 0.5s;
}
.text-site {
    color: var(--color-site) !important;
}
.text-site2 {
    color: var(--color-site-b) !important;
}
.text-site-h {
    color: var(--color-site-h) !important;
}
.navButton {
    display: flex;
    gap: 10px;
    text-align: center;
    text-decoration: none;
    color: #616161;
    font-family: inter-regular, sans-serif;
}
.navButton:hover .globe-icon, .navButton:hover .linkItem-light {
    color: var(--blue) !important;
}
.contant-btn {
    border: solid 1px var(--orange);
    border-radius: 6px;
    padding: 5px 15px;
    background: #FFFFFF;
    box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.1);
    color: var(--orange);
}
.contant-btn .contact-btn-text {
    color: var(--orange);
    font-family: 'inter-regular', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.46px;
    transition: ease all 0.5s;
}
.contant-btn:hover {
    border: solid 1px var(--blue);
}
.contant-btn:hover .contact-btn-text {
    color: var(--blue) !important;
}

.btn-text {
    font-family: "Sofia Pro", sans-serif;
    font-size: 15px;
}
.cover-main {
    height: auto;
}
.cover-main2 {
    min-height: 40vh;
    height: auto;
}
.coverText {
    font-size: 0.5em;
}
.coverText p {
    color: #ece7e7 !important;
    font-size: 18px;
    font-weight: bold;
    font-family: 'DM Sans', sans-serif;
}
 #svgcover {
    width: 100%;
    height: auto;
    transform: scale(1.1);
 }
 #svgcover2 {
     width: 100%;
     height: auto;
     transform: scale(1.1);

 }
 #phone {
     width: 90%;
     height: 700px;
     margin-right: -20px;
     transform: scale(1.150);
     transform-origin: center;
     position: absolute;
     margin-bottom: 20px;
     animation: phone 1s linear normal ;
 }
 #motifL{
     transform-origin: center;
     animation: rotate-motifL 5s linear infinite ;
 }
 .section2{
     background-color: #ffffff;
     height: auto;
     min-height: 25vh;
     color: #000;
 }
 .section4{
     background-color: #0f1f38;
     height: auto;
     min-height: 30vh;
     color: #fff;
 }
 .section3{
     background-color: #f4f7f9;
     min-height: 30vh;
     height: auto;
     color: #000;
 }
 .section3b{
     background-color: #0092FF;
     min-height: 30vh;
     height: auto;
     color: #000;
 }
 .section5{
     background-color: #fff;
     min-height: 30vh;
     height: auto;
     color: #000;
 }
 @keyframes phone {
    0%{
        transform: scale(0.150);
    }
    20%{
        transform: scale(0.5);
    }
    40%{
        transform: scale(1);
    }
    60%{
        transform: scale(1.100);
    }
    80%{
        transform: scale(1.150);
    }
    100%{
        transform: scale(1.150);
    }
 }

 .icConteint{
    background-color: #fff;
    width: 200px;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 200px;
    border-radius: 100px;
    overflow: hidden;
    align-self: center;
 }
 .icConteint2{
    background-color: transparent;
    width: 100%;
    height: 20vh;
    align-items: center;
    justify-content: center;
    display: flex;
 }

.cardservice{
    border-radius: 10px;
    -webkit-box-shadow: 0 0.1rem 1rem rgb(0 0 0 / 5%) !important;
    box-shadow: 0 0.1rem 1rem rgb(0 0 0 / 5%) !important;
    transition: all 0.5s;
}
.cardservice:hover{
    transform: scale(1.04);
    transition: all 0.5s;
}
.cardservice h4{
   font-size: 18px !important;
    font-weight: bold;
}
.cardservice p{
   font-size: 15px !important;
}

@keyframes rotate-motifL {
    0%{
        x:1000
    }
    20%{
        x:800
    }
    40%{
        x:600
    }
    60%{
        x:200
    }
    80%{
        x:600
    }
    100%{
        x:1000
    }
}
.heading{
    font-size: 2rem !important;
}
.nav-mobil .btn{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.nav-mobil .btn:hover, .nav-mobil .btn:active{
    color:var(--color-site);
}
#per1,#per2,#per3,#per4{
    transform-origin: center;
    animation: per1Anim 5s linear infinite ;
    fill: #d0cde1;
}

@keyframes per1Anim {
    0%{
        fill: #0f1f38;
    }
    10%{
        fill: #2c4d80;
    }
    20%{
        fill: #708ebb;
    }
    30%{
        fill: #a5b6d2;
    }
    40%{
        fill: #d0cde1;
    }
    50%{
        fill: #d0cde1;
    }
    60%{
        fill: #d0cde1;
    }
    70%{
        fill: #a5b6d2;
    }
    80%{
        fill: #708ebb;
    }
    90%{
        fill: #2c4d80;
    }
    100%{
        fill: #0f1f38;
    }
}
.card-teams{
    width: 100%;
    height: calc(80vh - 100px);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
    transition: all 0.5s;
}

.card-teams:hover {
    -webkit-box-shadow: 3px 2px 7px 2px rgba(0,0,0,0.16);
    box-shadow: 3px 2px 7px 2px rgba(0,0,0,0.16);
    border:solid 1px var(--color-site);
    transition: all 0.5s;
}
.card-teams2:hover {
    transition: all 0.5s;
}
.img-teams {
    filter: saturate(1.1);
    border-radius: 50%;
    transition: all 0.5s;
    object-fit: cover;
}
.card-teams h4 {
    font-weight: bold;
    font-size: 1.05em;
    color: #000;
    transition: all 0.5s;
}
.card-teams p{
    font-weight: bold;
    font-size: 0.8em;
    transition: all 0.5s;
}
#contactIcon{
    width: 70%;
    height: 100vh;
}
.cont p{
    font-weight: normal !important;
}
.container-layer{
    padding-top: 70px;
    min-height: 100vh;
}

.img-ordi{
    position: absolute;
    width: calc(40vw - 100px );
    margin-top: 150px;
    right: 180px;
    object-fit: cover;
}

.btn-app-cont{
    display: flex;
    justify-content: space-around;
    gap: 20px;
}
.btn-app{
    border: none;
    background-color: transparent;
    color: #ffffff ;
    text-decoration: none;
    transition: all 0.5s;
}
.btn-app:hover{
    color: var(--color-site);
    transition: all 0.5s;
    transform: scale(1.3);
}
.btn-app span{
    color: #ffffff ;
    transition: all 0.5s;
}
.dropdown-language{
    list-style: none;
}
.containt_cover{
    position: absolute;
    z-index: 700;
    background: rgba(0, 0, 0, 0.31);
    height: 85vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerS {
    text-align: center;
    line-height: 0.8;
    margin-bottom: 50px;
    margin-top: 50px;
}

.headerS p {
    margin: 0 auto;
    line-height: 2;
    color: var(--grayishBlue);
}

.box p {
    color: var(--grayishBlue);
}

.box {
    border-radius: 5px;
    box-shadow: 0 30px 40px -20px var(--grayishBlue);
    padding: 30px;
    margin: 20px;
}

@media (max-width: 450px) {
    .box {
        height: 200px;
    }
}

@media (max-width: 950px) and (min-width: 450px) {
    .box {
        text-align: center;
        height: 180px;
    }
    .image-container-2 {
        height: 200px !important;

    }
}

.cyan {
    border-top: 3px solid var(--cyan);
}
.red {
    border-top: 3px solid var(--red);
}
.blue {
    border-top: 3px solid var(--blue);
}
.orange {
    border-top: 3px solid var(--orange);
}

.h2 {
    color: var(--varyDarkBlue);
    font-weight: var(--weight3);
    font-size: calc(1.275rem );
}


@media (max-width: 950px) {
    .footerText3 {
        width: 100% !important;
    }
    .quickLink {
        text-align: center;
    }
}
.myInput{
    border: none;
    border-bottom: 2px solid #c9c3c3;
    border-radius: 0;
    height: 40px;
    transition: 0.2s;
    background-color: transparent;
    padding-left: 5px;
    font-size: 15px;
}
.myInput:focus{
    border: none;
    border-bottom: 2px solid var(--color-site-b);
    border-radius: 0;
    font-size: 13px;
    outline: 0;
    transition: 0.2s;
    background-color: transparent;
}
.myInput:focus::placeholder{
    color: var(--color-site-b);
    font-size: 17px;
    transition: 0.2s;
    background-color: transparent;
}
.myInput::placeholder{
    color: #000000;
    font-size: 15px;
    transition: 0.2s;
    background-color: transparent;
}

.myInput2{
    border: none;
    border-bottom: 2px solid #c9c3c3;
    border-radius: 0;
    height: 40px;
    transition: 0.2s;
    background-color: transparent;
    padding-left: 5px;
    font-size: 15px;
}
.myInput2:focus{
    border: none;
    border-bottom: 2px solid var(--color-site);
    border-radius: 0;
    font-size: 13px;
    outline: 0;
    transition: 0.2s;
    background-color: transparent;
}
.myInput2:focus::placeholder{
    color: var(--color-site);
    font-size: 17px;
    transition: 0.2s;
    background-color: transparent;
}
.myInput2::placeholder{
    color: #000000;
    font-size: 15px;
    transition: 0.2s;
    background-color: transparent;
}
.padInput{
    padding: 0 10px 0 10px;
}
.sectionIn{
    background-color: #ffffff;
    min-height: 30vh;
    height: auto;
    color: #000;
}

.snip1533 {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    color: #9e9e9e;
    display: inline-block;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 16px;
    margin: 10px 0 10px 0;
    position: relative;
    text-align: center;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    border-top: 5px solid var(--color-site);
}

.snip1533 *,
.snip1533 *:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
}

.snip1533 figcaption {
    padding: 13% 10% 12%;
}

.snip1533 figcaption:before {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    color: var(--color-site);
    content: "\f10e";
    font-size: 32px;
    font-style: normal;
    left: 50%;
    line-height: 60px;
    position: absolute;
    top: -30px;
    width: 60px;
}

.snip1533 blockquote {
    font-style: italic;
    font-weight: 300;
    margin: 0 0 20px;
    text-align: left !important;
    font-size: 13px;
}
.svgDetFlag{
    height: 100px;
    width: 100px;
    margin: 30px 0 30px 0;
    border-radius: 50px;
}
.svgDetFlag2{
    height: 100px;
    width: 100px;
    border-radius: 50px;
}

.bnt-colaps{
    display: flex;
    align-items: center;
    padding: 5px 10px 10px 5px;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    border-bottom: 1px #ffffff solid;
    color: #d5d2d2;
}
.bnt-colaps .svgDetFlag,.bnt-colaps .svgDetFlag2{
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin: 0 10px 0 10px;
}

/* Footer */
.nav-mobil .linkItem-light {
    font-size: 20px !important;
}

.footerMain {
    display: flex;
    flex-direction: column;
}

.footerFirstBloc {
    display: flex;
}
.ourDiversity {
    font-family: 'Clan Pro', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 27px;
    text-align: center;
    color: var(--orange);
}
.footerText2 {
    font-family: 'Clan Pro', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 53px;
    text-align: center;
    color: #0092FF;
}
.footerText3 {
    font-family: 'Clan Pro Regular', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    width: 768px;
}
.newLetter {
    position: relative;
    width: 80%;
    background: linear-gradient(130deg, #2563EB 10%, #0092FF 100%);
    border-radius: 10px;
    height: 318px;
    padding: 60px 0 70px;
    margin: 80px auto 40px;
}
.shapeLeft {
    position: absolute;
    top: 0;
    left: 0;
}
.shapeRight {
    position: absolute;
    right: 0;
    bottom: 0;
}
.newLetterText {
    font-family: 'Clan Pro', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 53px;
    text-align: center;
    color: #FFFFFF;
}
.form-control-customize {
    padding: 17px 16px 17px 20px;
    gap: 13px;
    width: 243px;
    height: 60px;
    background: transparent;
    border: 1px solid #D4D4D8;
    border-radius: 5px;
    outline: none;
}
.form-control-customize::placeholder {
    font-family: 'inter-light', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff !important;
}
.form-control-customize:focus {
    border: 1px solid var(--color-site);
    color: #ffffff !important;
}

.form-control:focus {
    border-color: #0061A9;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
  }

input.form-control {
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 5px;
    margin-top: 6px;
}
.btn-primary {
    min-width: 147px;
    height: 60px;
    background: var(--orange);
    border-radius: 5px;
    border: none;
}
.btn-primary:hover {
    background: #FFA500;
}
.btn-secondary-customized {
    height: 48px;
    background: #0092FF;
    border: none;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 5px;
    color: #fff;
}
.btn-primary-b {
    min-width: 147px;
    width: auto !important;
    height: 40px;
    background: #0092FF;
    border-radius: 40px;
    border: solid 1px #0092FF;
    color: #FFFFFF;
    font-size: 0.7rem !important;
}
.btn-primary-b:hover {
    min-width: 147px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 40px;
    border: solid 2px #0092FF;
    color: #0092FF;
}

.btn-primary-b-outline {
    min-width: 147px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 40px;
    border: solid 2px #0092FF;
    color: #0092FF;
}
.btn-primary-b-outline:hover {
    min-width: 147px;
    height: 40px;
    background: #0092FF;
    border-radius: 40px;
    border: solid 1px #0092FF;
    color: #FFFFFF;
}
.btn-secondary-customized:hover {
    background: #23a0ff;
    color: #fff;
}
.quickLink {
    background: #F8FAFC;
}
.reseaux-sociaux i {
    color:#090914;
    font-size: 20px;
}
.reseaux-sociaux i:hover {
    background: var(--orange);
    color: #FFFFFF;
    border: 1px solid var(--orange) !important;
}
.quickLinkTitle {
    font-family: 'Poppins-Medium', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #0092FF;
}
.quickLinkText, .quickLinkDescription {
    font-family: 'Poppins-Light', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #52525B;
}
.quickLinkText a:hover {
    color: var(--color-site-b);
    cursor: pointer;
}
.quickLinkText a {
    color: #52525B;
    text-decoration: none;
}

.copyrightText p {
    font-family: 'Poppins-Light', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #71717A;
}

.light-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
}
.orange-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FF9800;
}

/* Landing page */
.landingPageFirstBlock {
    background: url("../../assets/images/bg/bg-landing.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 9rem 0rem;
    height: 800px;
    width: 100%;
}
.landingPageFirstBlock p {
    font-family: 'Clan Pro Regular', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 21px;
    line-height: 27px;
    color: #212939;
}

.text-normal {
    font-family: 'Clan Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 102px;
    display: flex;
    align-items: center;
    text-align: center;
}

.blackWamanRight {
    background: url(../../assets/images/shot-pretty-black-woman-pointing-space-with-excitement-removebg-preview-left.png);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    height: 381px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fdba74;
    border-radius: 49% 51% 47% 53% / 49% 50% 50% 51%;
}
.blackWamanLeft {
    background: url(../../assets/images/shot-pretty-black-woman-pointing-space-with-excitement-removebg-preview-left.png);
    height: 381px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #0092FF;
    border-radius: 49% 51% 47% 53% / 49% 50% 50% 51%;
}

.landingText {
    font-family: 'Clan Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 100px !important;
    line-height: 120px !important;
    color: #0061A9;
}

.landingPageSecondBlock {
    padding: 0 100px;
}
.landingTextSecondBlock h1 {
    font-family: 'Clan Pro', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 53px;
    color: var(--blue);
}

.landingTextSecondBlock h2 {
    font-family: 'Clan Pro', sans-serif;
    font-weight: 500;
    font-size: 42px;
    line-height: 53px;
    color: #0061A9;
}

.landingTextSecondBlock p {
    font-family: 'Clan Pro Regular', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #303642;
}
.landing-page-third-block-mobile {
    display: none;
}

/* Third Part */
.cid-r59P4kAHrn{
    align-items: center;
    display: flex;
}
.mbr-black{
    color: #000000;
}
@media (max-width: 992px) {
    .cid-r59P4kAHrn .mbr-row > *{padding: 0;}
}
.cid-r59P4kAHrn .title-block{
    margin: auto;
    width: 100%;
}
@media (max-width: 992px) {
    .cid-r59P4kAHrn .title-wrap {
        margin-bottom: 2rem;
    }
}
.cid-r59P4kAHrn .mbr-section-btn, .cid-r59P4kAHrn .mbr-text {
    color: #767676;
}
.cid-r5vf7IBrgN .title-wrap, .cid-r5vf7IBrgN .title {
    width: 100%;
    padding: 0 1rem;
}
.mbr-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
}
.mobirise-loader {
    object-fit: fill;
}
.mbr-row-reverse {
    flex-direction: row-reverse;
}
@media (min-width: 768px) {
    .mbr-col-md-3{
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .mbr-col-md-4{
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .mbr-col-md-5{
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .mbr-col-md-6{
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .mbr-col-md-7{
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .mbr-col-md-8{
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .mbr-col-md-10{
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .mbr-col-md-12{
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
    }
}
@media (max-width: 767px){
    .mbr-col-sm-12{
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .text-md-left {
        text-align: center;
        margin-top: 20px;
    }
    .landingTextSecondBlock h1 {
        font-size: 30px;
    }
    .landingTextSecondBlock p {
        font-family: 'Clan Pro Regular', sans-serif;
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 20px;
        color: #303642;
        text-align: center;
    }
    .centered-block {
        justify-content: center !important;
    }
    .landingText {
        font-size: 40px !important;
        line-height: 40px !important;
    }
    .landingPageFirstBlock {
        padding-top: 80px !important;
        background: none;
        height: 400px;
    }
    .landingPageThirdBlock {
        display: none;
    }
    .landing-page-third-block-mobile {
        display: block;
    }
    .team-mobile-section {
        display: block;
    }
    .team-mobile-section h1 {
        font-size: 30px;
    }
    .testimonial .test-user-photo {
        width: 100px !important;
        height: 100px !important;
    }
    .testimonial .test-content {
        padding-top: 0 !important;
    }
    .history-milestone h1 {
        font-size: 40px !important;
    }
    .swiper-button-next,
    .swiper-button-prev {
        display: none !important;
    }
    .teamMember1 {
        display: none !important;
    }
    .contactInfo {
        display: none !important;
    }

    .certification-block h1 {
        font-size: 32px !important;
        margin-bottom: -30px !important;
    }
    .certification-block h2 {
        font-size: 25px !important;
    }
    .certification-block img {
        width: 100% !important;
        height: 205.36px !important;
    }
    .landingPageAboutBlock {
        background-image: none !important;
        padding: 6rem 20px !important;
        grid-template-columns: 1fr !important;
    }
    .landingPageAboutBlock .mbr-section-title {
        color: var(--color-site-b) !important;
        line-height: 40px;
    }
    .landingPageAboutBlock p {
        color: #212939 !important;
        margin-top: 30px !important;
    }
    .certication-icon {
        width: 140px !important;
    }
    .client-avatar {
        display: block !important;
    }
    .crs-section-one .landingTextSecondBlock h1 {
        font-size: 30px !important;
        line-height: 40px !important;
    }
    .crs-section-one img, .crs-section-two img, .crs-section-three img {
        height: 310px !important;
        border-radius: 10px;
    }
    .crs-section-two {
        height: 120px !important;
    }
    .crs-section-two h1 {
        font-size: 20px !important;
        line-height: 30px !important;
    }
    .landingTextSecondBlock.crs-section-three h2 {
        font-size: 20px !important;
    }
    .ogri-section-5-badget {
        padding: 5px 0 0 25px !important;
    }
    .ogri-section-5-badget div span {
        font-size: 20px !important;
        line-height: 30px !important;
    }
}
@media (min-width: 992px){
    .mbr-col-lg-6{
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

@media (max-width: 991px){
    .mbr-px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .landingTextSecondBlock {
        text-align: center;
    }
}

.landingPageThirdBlock {
    height: 950px;
    background: #F5F5F5;
    z-index: 1;
    padding: 100px;
}

.landingTextThirdBlock h1 {
    font-family: 'Poppins-Medium', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    line-height: 62px;
    letter-spacing: -2px;
    color: #FF9800;
}
.landingTextThirdBlock p {
    font-family: 'Poppins-Light', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #303642;
    margin: 5px 0 20px;
}
.landingPageThirdBlock .link {
    font-family: 'Poppins-Medium', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.2px;
    text-decoration-line: underline;
}
.landingPageThirdBlock .icon-row {
    transform: rotate(-45deg);
}
.bg-1 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/bg-1.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-2 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/bg-2.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-3 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/bg-3.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-4 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/bg-4.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-d-1 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/d5.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-d-2 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/d2.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-d-3 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/d3.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-d-4 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/d4.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.ourBusinnessImage {
    width: 284.4px;
    height: 568px;
    border-radius: 20px;
    padding: 0 20px;
    position: relative;
    z-index: 1;
}
.bg-1:hover, .bg-2:hover, .bg-3:hover, .bg-4:hover {
    filter: contrast(0.7);
}
.ourBusinnessImageText {
    position: absolute;
    bottom: 0;
}
.ourBusinnessImageText h1 {
    font-family: 'Poppins-Medium', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
}
.ourBusinnessImage p {
    font-family: 'Poppins-Light', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: -8px;
}

/* Team */
.landingTextSecondBlock2 {
    margin: 20px 0 100px 0 !important;
}
.landingTextSecondBlock3 {
    margin: 0 0 100px 0 !important;
}
.teamBlock {
    height: 900px;
    background: #f5bcbc;
}
.landingTextTeamBlock h1 {
    font-family: 'Poppins-Medium', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 62px;
    text-align: center;
    letter-spacing: -2px;
    color: #0061A9;
}

.team-member {
    height: 292px;
    background: #F9FAFB;
    padding: 24px;
    margin: 10px;
}
.team-member img {
    width: 80px;
    height: 80px;
    object-fit: cover;
}
.team-member .text-muted {
    font-family: 'Clan Pro Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0061A9 !important;
}
.team-member .description {
    color: #667085;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
}
.teamMember {
    width: 280px;
    height: 280px;
    background: #FFFFFF;
    border: solid 10px #ffffff;
    margin-left: -50px;
    border-radius: 50%;
    text-align: center;
}
.teamMember-image {
    width: 260px;
    height: 260px;
    margin: auto;
    border-radius: 50%;
    object-fit: cover;
}
.teamMember1 {
    height: 320px;
    display: grid;
    width: fit-content;
    grid-template-columns: 2fr 2fr 2fr 3fr 2fr 2fr 2fr ;
    margin: 70px 0 120px 50% !important;
    transform: translateX(-49%);
    overflow: hidden;
    padding-top: 100px;
}

.teamMember1 .teamMember.focusMember{
    width: 380px;
    height: 380px;
    margin: -90px -20px 0 -73px;
    position: relative;
}

.teamMember1 .teamMember.focusMember .teamMember-image{
    width: 360px;
    height: 360px;
    margin: auto;
}

.landingPageFourthBlock {
    z-index: 1;
    position: relative;
    width: 100vw;
    overflow-x: hidden;
}

.testimonial .test-user-photo {
    width: 215.1px;
    height: 239px;
    border-radius: 15px;
}
.testimonial-comment {
    font-family: 'Poppins-Light', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #090914;
}
.testimonial-author span {
    font-family: 'Poppins-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #090914;
    margin-right: 40px !important;
}
.test-fuction {
    font-family: 'Poppins-Light', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #64748B;
}
.what-we-do-text {
    line-height: 30px !important;
    margin-top: 16px;
    font-size: 20px;
}

/* History */
.history-milestone h1 {
    font-family: 'Poppins-Medium', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 102px;
    color: #E4E4E7;
}
.history-milestone img {
    height: 350px;
    object-fit: cover;
    border-radius: 30px;
}
.history-milestone p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #3F3F46;
}
.card-hover {
    transition: all 0.3s ease-in-out;
}
.card-hover:hover {
    transform: scale(0.9);

}

/* About */
.landingPageAboutBlock2 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../assets/images/bg/landingPageAboutBlock2.png");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    align-items: flex-start;
    padding: 6rem 2rem;
    height: 520px;
    display: grid;
    grid-template-columns: 4fr 3fr;
}

.landingPageAboutBlock3 {
    background: url("../../assets/images/bg/world.png");
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: center;
    align-items: flex-start;
    height: 920px;
    padding: 6rem 2rem;
}
.landingPageAboutBlock2 .mbr-section-title {
    font-size: 29px;
    line-height: 40px;
}
.landingPageAboutBlock .what-we-do-text,
.landingPageAboutBlock2 .what-we-do-text {
    color: #ffffff;
    font-size: 18px;
}
.landingPageAboutBlock .about-tittle {
    color: #2c4d80;
    background: #888d91;
    border-radius: 20px;
    padding: 7px 20px;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 15px;
}

.about-tittle {
    color: #0061A9 !important;
    background: #F5F6FF !important;
    border-radius: 20px;
    padding: 7px 20px;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 15px;
}

.landingPageAboutBlock2 .about-tittle-2 {
    color: #e0a718 !important;
    border-radius: 20px;
    padding: 0 0 0;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 18px;
}

.about-distributed-text {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 28px;
    color: #667085 !important;
}

.about-team-description {
    background: linear-gradient(0deg, rgba(16, 0, 109, 0.2), rgba(139, 139, 139, 0.562));
    padding: 15px;
}

.contactInfo img {
    height: 686px;
    background: #dbdbdb;
    object-fit: cover;
}
.contactForm label {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}
.titre-values2{
    font-weight: bold;
    font-size: 3.8rem;
    color: var(--color-site-b) !important;
}
.titre-values{
    font-weight: bold;
    font-size: 3.8rem;
}
.titre-values-next{
    font-weight: 600;
    font-size: 2.6rem;
}
.rounded-site{
    border-radius: 5%;
}
.site-text-style{
    font-family: 'Clan Pro Regular', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 25px;
    color: #303642;
}

/* Certification */
.certification-block img {
    width: 594px;
    height: 498px;
    object-fit: cover;
}
.certification-block h1 {
    font-family: 'Clan Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 102px;
    color: #0061A9;
}
.certification-block h2 {
    font-family: 'Clan Pro', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 53px;
    color: var(--color-site-b);
}
.certification-block p {
    font-family: Arial, Helvetica, sans-serif;
    color: #3F3F46;
}
.certification_img1 {
    height: 350px;
    object-fit: cover;
    border-radius: 30px;
    background: #dbdbdb;
}
.text-values-certification {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px !important;
    line-height: 28px;
    color: #667085 !important;
}
.certication-icon {
    width: 200px;
}

/* CLIENT */
.client-bloc {
    height: 700px;
    width: 100%;
    background: #F1F5F9;
}
.client-bloc-2:nth-child(odd) {
    background: #F1F5F9;
    padding-bottom: 70px;
}
.client-bloc-2:nth-child(odd) .first-block {
    order: 2;
}
.client-bloc-2:nth-child(odd) .second-block {
    order: 1;
}
.client-badget-title {
    color: #000000;
    background: #ffffff;
    border-radius: 20px;
    font-family: Arial, Helvetica, sans-serif !important;
}
.client-badget-title span {
    font-size: 18px;
    font-weight: 600;
}
.citation-section-image {
    height: 485px;
    width: 538.2px;
    object-fit: cover;
    border-radius: 10px;
}
.citation-section-2 {
    position: relative;
    background: url("../../assets/images/cl-1.png");
    border-radius: 10px;
    background-repeat: no-repeat;
    object-fit: contain;
    height: 485px;
}
.text-secondary-client {
    color: #A1A1AA !important;
    font-size: 14px;
}
.client-avatar {
    display: none;
}
.citation-section-2-text {
    position: absolute;
    bottom: 20px;
}
.bg-client-1 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/bg-client-1.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-client-2 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/bg-client-2.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-client-3 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/bg-client-3.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-client-1:hover, .bg-client-2:hover, .bg-client-3:hover, .bg-4:hover {
    filter: contrast(0.7);
}
.client-section .text-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 auto;
}
.client-section label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

/* CRS */
.crs-section-one h1 {
    font-size: 60px !important;
    line-height: 80px;
}
.crs-section-one img {
    width: 503px;
    height: 617px;
}
.crs-section-two {
    position: relative;
    height: 236px;
    background-color: #F8FAFC;
}
.crs-section-two h1 {
    font-family: 'Clan Pro', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 53px;
    text-align: center;
    color: var(--color-site-b);
}
.crs-section-three img {
    width: 503px;
    height: 485px;
}
.crs-section-four {
    background-color: #F8FAFC;
    text-align: center;
    height: 190px;
}
.crs-section-four span {
    color: var(--color-site-b)
}

.cover-main3 {
    min-height: 10vh;
    height: auto;
}
.container-ride {
    position: relative;
    max-height: 45vh;
    overflow: hidden;

}
.image-ride {
    margin-top: -6vh;
}
.btn-link-header {
    text-decoration: none !important;
    margin-right: 30px;
}
.icone{
    margin-right: 10px;
}
.tryFree{
    height: 40vh;
    background:  url('../images/d6.png')  center no-repeat ;
   border-radius: 20px !important;
    background-size: cover;
}
.image-container-2 {

    width: 100%;
    position: unset !important;
}

.card-site {
    border-radius: 5px;
    height: 450px !important;
}
.image-2 {
    object-fit: fill;
    width: 100% !important;
    position: relative !important;
    height: 100% !important;
}

.parteners-description span {
    font-family: 'Clan Pro', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    color: #0061A9;
}

.police h1, .cookie h1, .disclaimer h1 {
    font-family: 'Clan Pro', sans-serif;
    font-weight: 500;
    font-size: 42px;
    line-height: 53px;
}
.police p, .cookie p, .disclaimer p {
    font-family: 'Poppins-Light', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #3F3F46;
}
.police .decriptif-text {
    font-family: 'Clan Pro', sans-serif;
}
.police .list, .privacy ol, .cookie ul, .disclaimer ul {
    font-family: 'Poppins-Light', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #3F3F46;
}
.police .list a {
    color: #0092FF;
}
.privacy ul li, .privacy ol li, .cookie ul li, .disclaimer ul li {
    margin-bottom: 10px;
}
.cookie-2-3 ul li {
    font-family: 'Poppins-Medium', sans-serif;
    font-weight: 500;
}
.bullet-point::before {
    content: "\2022";
    margin-right: 0.4em;
}
ol.list-content { counter-reset: item; }
ol.list-content li { display: block; }
ol.list-content li:before {
    content: counter(item) ". ";
    counter-increment: item;
    color: #0092FF;
}

ol.list-content-cookies { counter-reset: item; }
ol.list-content-cookies li.order { display: block; }
ol.list-content-cookies li.order:before {
    content: counter(item) ". ";
    counter-increment: item;
    color: #0092FF;
}
.ogri-section-5-badget {
    padding: 14px 0 0 25px;
    width: 107px;
    height: 180px;
    background: rgba(255, 152, 0, 0.2);
}
.ogri-section-5-badget div {
    position: absolute;
}
.ogri-section-5-badget div span {
    font-family: 'Clan Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 41px;
    color: #0061A9;
}
.ogri-section-5-badget div h1 {
    font-family: 'Clan Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 106px;
    line-height: 135px;
    color: #0061A9;
}
.cover-text {
    padding-left: 310px;
}

@media (max-width: 1218px) {
    .cover-text {
        padding-left: 5%;
    }
}
@media (max-width: 1201px) {
    .cover-text {
        padding-left: 5%;
    }
    .cover-text h1 {
        font-size: 60px !important;
        line-height: 60px !important;
        text-align: left !important;
    }
}
@media (max-width: 991px) {
    .cover-text {
        padding-left: 5%;
    }
    .cover-text h1 {
        font-size: 60px !important;
        line-height: 60px !important;
        text-align: center !important;
    }
}
@media (max-width: 280px) {
    .cover-text h1 {
        font-size: 40px !important;
        line-height: 60px !important;
        text-align: center !important;
    }
}
@media (max-width: 767px) {
    .d-flex.flex-column {
      flex-direction: column !important;
    }
}
.action-btn button:disabled {
    background-color: #0060a9ce !important;
    border-color: #0060a9ce !important;
    color: #fff !important;
}

.blue-text {
    color: #0061A9;
}

.text-sm {
    font-size: 16px;
}

.text-md {
    font-size: 24px;
}

.pt-40 {
    padding-top: 40px !important;
}
