/* ............................................................... */
/* ............................................................... */
/* ............................................................... */
#retrobg {
    position: relative;
    overflow: hidden;
    height: 85vh;
    width: 100%;
    color: #0092FF;
    background-color: #000;
}

/* ............................................................... */
#retrobg-sky {
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 55%;
    background: linear-gradient(#0092FF 75%, #249);
}

/* ............................................................... */
#retrobg-sunWrap {
    position: relative;
    width: 40%;
    margin-bottom: -15%;
}

#retrobg-sun {
    --glow-color: #FF6B4A;
    padding-top: 100%;
    border-radius: 50%;
    background-image: linear-gradient(#FF9800, #FF6B4A, #FF6B4A 50%);
    box-shadow: 0 0 160px 80px var(--glow-color);
    animation: 2s ease infinite alternate retrobg-sun-glow-anim;
}

.retrobg-shutdown #retrobg-sun {
    background-image: linear-gradient(#000, #000 40%);
    --glow-color: #000;
}

@keyframes retrobg-sun-glow-anim {
    from {
        box-shadow: 0 0 160px 80px var(--glow-color);
    }
    to {
        box-shadow: 0 0 200px 95px var(--glow-color);
    }
}
/* ............................................................... */
#retrobg-stars {
    position: absolute;
    width: 100%;
    height: 100%;
}

.retrobg-star {
    position: absolute;
    border-radius: 50%;
    width: 2px;
    height: 2px;
    background-color: #fff;
}

/* ............................................................... */
#retrobg-mountains {
    position: absolute;
    width: 100%;
    height: 30%;
}

.retrobg-mountain {
    position: absolute;
    width: 30%;
    height: 100%;
    background-image: linear-gradient(#000 70%, #111 85%, #fff1);
}

#retrobg-mountains-left {
    left: 0;
    clip-path: polygon(0% 100%, 0% 55%, 5% 60%, 10% 55%, 20% 50%, 25% 42%, 30% 38%, 33% 35%, 40% 45%, 50% 50%, 60% 70%, 70% 85%, 75% 82%, 80% 91%, 85% 90%, 90% 95%, 95% 98%, 100% 100%);
}

#retrobg-mountains-right {
    right: 0;
    clip-path: polygon(0% 100%, 5% 95%, 10% 85%, 15% 87%, 20% 80%, 25% 78%, 30% 65%, 40% 70%, 50% 57%, 60% 53%, 67% 68%, 70% 70%, 75% 66%, 80% 55%, 90% 50%, 95% 60%, 100% 57%, 100% 100%);
}

/* ............................................................... */
#retrobg-cityWrap {
    position: absolute;
    width: 50%;
    margin-left: -1%;
}

#retrobg-city {
    padding-top: 20%;
}

.retrobg-building {
    position: absolute;
    width: 5%;
    height: 100%;
    bottom: 0;
    border-radius: 4px 4px 0 0;
    background-image: linear-gradient(0deg, rgba(17, 17, 34, 0), #303642 30px, #000);
}

.retrobg-building:nth-child(odd) {
    background-image: linear-gradient(0deg, rgba(24, 24, 42, 0), #303642 30px, #000);
}

.retrobg-antenna::after {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: calc( -1px - 5% );
    bottom: 100%;
    width: 10%;
    min-width: 2px;
    height: 33%;
    background-color: #000;
}

/* ............................................................... */
#retrobg-ground {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 45%;
    bottom: 0;
    border-top: 2px solid #FF6B4A;
    background-color: #000;
}

.retrobg-shutdown #retrobg-ground {
    border-color: #000;
}

#retrobg-groundShadow {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#000 0%, transparent);
}

/* ............................................................... */
#retrobg-linesWrap {
    height: 100%;
    perspective: 1000px;
    perspective-origin: center top;
}

#retrobg-lines {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: top center;
    animation: 0.35s linear infinite retrobg-lines-anim;
}

.retrobg-shutdown #retrobg-lines {
    animation-duration: 5s;
}

@keyframes retrobg-lines-anim {
    from {
        transform: rotateX(84deg) translateY(0);
    }
    to {
        transform: rotateX(84deg) translateY(100px);
    }
}
#retrobg-hlines,
#retrobg-vlines {
    position: absolute;
    left: calc( ( 900% - 100% ) / -2 );
    width: 900%;
    height: 500%;
}

#retrobg-vlines {
    display: flex;
    justify-content: center;
}

.retrobg-hline,
.retrobg-vline {
    width: 100%;
    height: 100%;
    background-color: currentColor;
}

.retrobg-hline {
    height: 3px;
}

.retrobg-vline {
    width: 3px;
}

.retrobg-hline + .retrobg-hline {
    margin-top: 98px;
}

.retrobg-vline + .retrobg-vline {
    margin-left: 48px;
}