.container-error {
    background-image: url(../../assets/images/bg/error_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 60%;
    height: calc(70vh + 0px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
}
.container-error h1 {
    font-family: 'Clan Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    text-align: center;
    color: #0092FF;
}
#svgnot {
    width: calc(60vw - 100px);
    height: auto;
}
#sol {
    transform-origin: center;
    animation: sol 5s linear infinite ;
}
@keyframes sol {
    0%{
        cx:349.24878;
        cy:100;

    }
    20%{
        cx:449.24878;
        cy:80;

    }
    40%{
        cx:549.24878;
        cy:60;
    }
    60%{
        cx:649.24878;
        cy:51;

    }
    80%{
        cx:549.24878;
        cy:80;
    }
    100%{
        cx:349.24878;
        cy:100;
    }

}
.container-error a {
    color: #fff;
    text-decoration: none;
}
.container-error a:hover {
    color: #f47a71;
}
.go-back:hover {
    filter: brightness(1.2);
}

@media screen and (max-width: 768px) {
    .container-error {
        width: 100%;
        padding: 10px;
        background-image: none;
    }
    .error-text {
        text-align: center;
    }
    .coming-soon-text {
        font-size: 90px !important;
    }
}